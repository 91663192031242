$(document).ready(function(){
    //TRIGGER RESPONSIVE MENU
    $('#trigger').click(function(){
        if($('body').hasClass('open')){
            $('body').removeClass('open');
        }else{
            $('body').addClass('open');
        }
    });

    //TRIGGER MAILCHIMP POPUP
    $('footer .newsletter').on('click',function(){
        if($(this).hasClass('open')){
            $(this).removeClass('open');
            $('.mailChimpPopup').slideUp();
        }else{
            $(this).addClass('open');
            $('.mailChimpPopup').slideDown();
        }
    });

    var searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('mailchimp')) {
        $('footer .newsletter').addClass('open');
        $('.mailChimpPopup').slideDown();
    }
    $('.mailChimpPopup .closebutton').on('click',function(e){
        e.preventDefault();
        $('footer .newsletter').removeClass('open');
        $('.mailChimpPopup').slideUp();
    });

    //HOME HEADER HEIGHT
    function headerheight(){
      $screenheight = $(window).height();
      $(".home header").css({height: $screenheight,});
    }
    headerheight();

    //TRIANGLE + BORDERS HEADER
      function trianglewidth(){
        $screenwidth = $(window).width();
        $halfscreen = $screenwidth / 2;
        $triangletrans = $halfscreen - 30;
        $("header .triangleleft").css({'border-width': '100px 0 0 ' + $halfscreen +'px'});
        $("header .triangleright").css({'border-width': '0 0 100px ' + $halfscreen +'px'});
        $("header .trianglelefttransparent").css({'border-width': '100px 0 0 ' + $triangletrans +'px'});
        $("header .trianglerighttransparent").css({'border-width': '0 0 100px ' + $triangletrans +'px'});

      }
      trianglewidth();

      //CIRKLE HEIGHT
      function cirkleheight(){
        $widthcirkle = $("#productenhome ul li .topimage").width();
        $("#productenhome ul li .topimage").css({height: $widthcirkle,});
      }
      cirkleheight();

      //TRIANGLE BLUE SECTION
      function triangleblue(){
          $screenwidth = $(window).width();
          $(".traingleTop").css({'border-width': '60px ' + $screenwidth +'px 0 0'});
          $(".traingleBottom").css({'border-width': '0 0 60px ' + $screenwidth +'px'});
      }
      triangleblue();

      //HEIGHT SERVICE IMAGE
      function heightimageservice(){
          $heightcontentfirst = $("#servicehome ul li.first .content").outerHeight();
          $heightcontentsecond = $("#servicehome ul li.second .content").outerHeight();
          $("#servicehome ul li.first .image").css({height: $heightcontentsecond,});
          $("#servicehome ul li.second .image").css({height: $heightcontentfirst,});
      }
      heightimageservice();

      //HEIGHT IMAGE BACKGROUND SERVICE
      function heighttriangleservice(){
          $heightshape = $("#servicehome .wrappershape .shape").height();
          $heighthalf = $heightshape / 2;
          $("#servicehome .wrappershape .shape .triangle1").css({'border-width': $heighthalf +'px 300px ' + $heighthalf +'px 0'});
          $("#servicehome .wrappershape .shape .triangle2").css({'border-width': $heighthalf +'px 0 ' + $heighthalf +'px 300px'});
      }
      heighttriangleservice();

      //TRIANGLES FOOTER
      function footertriangle(){
          $screenwidth = $(window).width();
          $screenhalf = $screenwidth / 2;
          $("footer .trianglehalfright").css({'border-width': '70px ' + $screenhalf +'px 0 0'});
          $("footer .trianglehalfleft").css({'border-width': '0 ' + $screenhalf +'px 70px 0'});
      }
      footertriangle();

      //POPUP
      setTimeout(
          function() {
              $('.pop-up').addClass('show');

              setTimeout(
            function()
            {
                $('.pop-up.show').removeClass('show');
            }, 10000);
        }, 2500);

      $('.fa-times').on('click', function(){
          $('.pop-up.show').removeClass('show');
      })

      $(window).resize(function(){
          trianglewidth();
          headerheight();
          cirkleheight();
          triangleblue();
          heightimageservice();
          heighttriangleservice();
          footertriangle();
      });

      // Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });

});

equalheight = function(container){

var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {

   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;

   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}

$(window).load(function() {
  equalheight('#productenhome ul li');
  equalheight('#productssub ul li');
});


$(window).resize(function(){
  equalheight('#productenhome ul li');
  equalheight('#productssub ul li');
});
